<template>
    <div class="send-contact">
        <StackRouterHeaderBar />
        <h1 class="title m-b-32 m-l-16">{{ 'SEND_CHECK_TITLE' | translate }}</h1>

        <main class="p-16">
            <form class="form m-b-40">
                <p class="label">친구의 이름</p>
                <input class="input m-b-28" placeholder="실명을 입력해주세요" v-model="name" />
                <p class="label">친구의 전화번호 (혹은 카카오톡 ID)</p>
                <input class="input" placeholder="입력해주세요" v-model="number" />
            </form>

            <div class="cautions m-b-16">
                <p class="m-b-6 f-medium">
                    연락처를 보낸 후, <span class="f-bold">상대방이 소개팅권(유료)</span>을 사용하면
                </p>
                <p class="m-b-6">
                    <span class="f-bold c-primary">친구의 연락처>상대방에게</span> 전달되고,<br />
                    <span class="f-bold c-primary">상대방의 연락처>{{ $nameOrNick(me) }}님께</span> 전달돼요!
                </p>
                <p class="f-medium">상대방의 연락처를 친구에게 전달해주세요!</p>
            </div>

            <div class="flex-row">
                <Checkbox class="flex-wrap m-r-8" v-model="checked" />
                <p class="text-underline c-text-dark" @click="openPrivacy">개인정보 수집 및 이용 동의</p>
            </div>
        </main>

        <BottomButton @click="onClick" label="SEND_CHECK_TITLE" :disabled="buttonDisabled" />
    </div>
</template>

<script>
import StackRouterHeaderBar from '@/components/app/StackRouterHeaderBar'
import BottomButton from '@/components/app/BottomButton.vue'
import chatService from '@/services/chat'

export default {
    components: { StackRouterHeaderBar, BottomButton },
    name: 'SendContactPage',
    data: () => ({
        name: '',
        number: '',
        checked: false,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        buttonDisabled() {
            return !this.checked || this.name.length === 0 || this.number.length === 0
        },
    },
    methods: {
        openPrivacy() {
            this.$stackRouter.push({ name: 'RealFriendPrivacyPage' })
        },
        onClick() {
            if (this.checkValidation(this.number)) {
                this.$modal
                    .custom({
                        component: 'ModalRemindBeforeSendRealFriendContact',
                        options: {
                            name: this.name,
                            number: this.number,
                        },
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.send()
                        }
                    })
            }
        },
        async send() {
            try {
                const { msg } = await chatService.requestIntroduce(this.$store.getters.chat.id, {
                    phone_number: this.number,
                    name: this.name,
                })

                this.$toast.success(msg)
                this.$stackRouter.pop()
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        checkValidation(number) {
            const regex = /[a-zA-Z]/g // 알파벳 포함?

            // 카톡id
            if (regex.test(number)) {
                const notEnglishOrNumber = /[^A-Za-z0-9]/ // 한글, 특수문자?

                if (notEnglishOrNumber.test(number)) {
                    this.$alert('유효한 카카오톡 id 형태가 아닙니다.')

                    return false
                }
            } else {
                const phoneNumberFormat = /010-*\d{4}-*\d{4}/ // 010-xxxx-xxxx or 010xxxxxxxx 포멧만 허용

                if (!phoneNumberFormat.test(number)) {
                    this.$alert(
                        `유효한 전화번호 형태가 아닙니다. 전화번호는 '010xxxxxxxx' 혹은 '010-xxxx-xxxx'형태로만 입력 가능합니다.`,
                    )

                    return false
                }
            }

            return true
        },
    },
}
</script>

<style lang="scss" scoped>
.send-contact {
    .title {
        font-size: 24px;
        font-weight: 500;
        color: black;

        @include f-medium;
    }

    .form {
        .label {
            margin-bottom: 12px;
            font-size: 14px;
            color: $grey-08;

            @include f-medium;
        }

        .input {
            width: 100%;
            height: 48px;
            padding: 12px 16px 14px 16px;
            appearance: none;
        }
    }

    .cautions {
        padding: 16px;
        background-color: $grey-01;
        border-radius: 10px;
        font-size: 13px;
        color: $grey-08;
        line-height: normal;
    }

    .checkbox {
        border-width: 2px;
    }

    ::v-deep .bottom-button {
        position: absolute;
    }
}
</style>
